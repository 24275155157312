import classNames from "classnames";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { usePrimaryRoles } from "@ugg/shared/api/requests/champions/primary-roles";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { useUGGAssetsContext } from "@ugg/shared/components/UGGAssetsProvider";

interface CountersSEOProps {
  className?: string;
  patch: string;
  displayPatch: string;
}

export default function CountersSEO(props: CountersSEOProps) {
  const { championId } = useChampionProfileContext();
  const { className, patch, displayPatch } = props;

  const { data: primaryRoles } = usePrimaryRoles({ ssr: true });
  const roles = primaryRoles && primaryRoles[championId];

  const { staging } = useUGGAssetsContext();
  const envDir = staging ? "staging" : "prod";
  const { data, loading, error } = useJSONFetcher(
    `https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/seo-champion-names.json`,
    { ssr: true },
  );

  const roleTextMap: Record<number, string> = {
    1: "Jungle",
    2: "Support",
    3: "ADC",
    4: "Top Lane",
    5: "Mid Lane",
  };
  const season = patch.split("_")[0];
  const roleText = roles ? roleTextMap[roles[0]] : "";
  const seoNames = data && data[championId];

  if (!data || error || loading) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      {`Use win rate and GD15 to find the best ${roleText} champion who counters ${seoNames["name"]}. Win Champion Select with ${
        seoNames["altName"] || seoNames["name"]
      } counters for \n LoL S${season} Patch ${displayPatch}. ${
        roles
          ? `Main Role Order: ${roleTextMap[roles[0]]} > ${roleTextMap[roles[1]]} > ${roleTextMap[roles[2]]} > ${
              roleTextMap[roles[3]]
            } > ${roleTextMap[roles[4]]}`
          : ""
      }`}
    </div>
  );
}
