import { Switch, Route, Redirect } from "react-router-dom";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import loadable from "@loadable/component";

const Overview = loadable(() => import("components/Champions/Overview"), {
  resolveComponent: (components) => components.Overview,
});
const CountersContainer = loadable(() => import("components/Champions/Counters/CountersContainer"));
const Matchups = loadable(() => import("components/Champions/Matchups/Matchups"), {
  resolveComponent: (components) => components.Matchups,
});
const ItemsTableContainer = loadable(() => import("components/Champions/ItemsTable/ItemsTableContainer"));
const Items = loadable(() => import("components/Champions/Items"));
const RunesTableContainer = loadable(() => import("components/Champions/RunesTable/RunesTableContainer"));
const Runes = loadable(() => import("components/Champions/Runes"));
const SpellsAbilities = loadable(() => import("components/Champions/SpellsAbilities"));
const ChampionDuos = loadable(() => import("components/Champions/Duos/ChampionDuos"), {
  resolveComponent: (components) => components.ChampionDuos,
});

export function ChampionProfileRoutes() {
  return (
    <Switch>
      <Route path={AppRoutes.CHAMPION_PROFILE_COUNTERS} component={CountersContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_MATCHUPS} component={Matchups} />
      <Route path={AppRoutes.CHAMPION_PROFILE_ITEMS} component={ItemsTableContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_ITEM_PATHS} component={Items} />
      <Route path={AppRoutes.CHAMPION_PROFILE_RUNES_TABLE} component={RunesTableContainer} />
      <Route path={AppRoutes.CHAMPION_PROFILE_RUNE_SETS} component={Runes} />
      <Route path={AppRoutes.CHAMPION_PROFILE_SPELLS_ABILITIES} component={SpellsAbilities} />
      <Route path={AppRoutes.CHAMPION_PROFILE_DUOS} component={ChampionDuos} />
      {/*  
        Keep overview routes last because special game mode routes can conflict with above routes
        (e.g. CHAMPION_PROFILE_ITEM_PATHS, CHAMPION_PROFILE_RUNES_TABLE)
      */}
      <Route path={[AppRoutes.CHAMPION_PROFILE_BUILD, AppRoutes.CHAMPION_PROFILE_BUILD_SPECIAL_GAME_MODE]} component={Overview} />
      <Redirect from={AppRoutes.CHAMPION_PROFILE_ROOT} to={AppRoutes.CHAMPION_PROFILE_BUILD} />
    </Switch>
  );
}
