import { useEffect } from "react";
import classNames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import MediaQuery from "components/MediaQuery";
import { ChampionProfileProvider } from "@ugg/shared/components/Champions/ChampionProfileProvider";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { ChampionProfileBackground } from "@ugg/shared/components/Champions/ChampionProfileBackground";
import { ChampionProfileHeader } from "@ugg/shared/components/Champions/ChampionProfileHeader/ChampionProfileHeader";
import { ChampionProfilePageNav } from "@ugg/shared/components/Champions/ChampionProfilePageNav";
import { ChampionProfileFilterManager } from "@ugg/shared/components/Champions/ChampionProfileFilterManager";
import { ChampionProfileRoutes } from "./ChampionProfileRoutes";
import Error404 from "components/Error404";
import { FadeTransitionWrapper } from "@ugg/shared/components/common/FadeTransitionWrapper";
import { RampLeftRail, RampRightRail } from "components/Ads/RampRails";
import { useLanguage } from "lib/hooks/languageHooks";
import { useValidatedChampionProfileParams } from "@ugg/shared/api/requests/champions/common";
import { useChampionProfileSEO } from "@ugg/shared/utils/seo-helpers";

export function ChampionProfile() {
  return (
    <ChampionProfileProvider>
      <ChampionProfileContent />
    </ChampionProfileProvider>
  );
}

function ChampionProfileContent() {
  const location = useLocation<{ scrollTop?: boolean }>();
  const { isLoading, isError, page, championId, championData, role } = useChampionProfileContext();
  const [language, setLanguage] = useLanguage();
  const params = useParams<{ language?: string; multi?: string }>();
  const getChampionProfileSEO = useChampionProfileSEO();
  const validatedParamsWithDefaults = useValidatedChampionProfileParams(championId, page, { keepDefaultParams: true, ssr: true });

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state && location.state.scrollTop) {
      window && window.scrollTo(0, 0);
    }
  }, [location.state && location.state.scrollTop]);

  useEffect(() => {
    if (params.language) {
      setLanguage(params.language);
    }
  }, [params.language]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <RailAdsContainer>
      <div className="champion-profile-main-page">
        <div className="champion-profile-content-container content-side-padding">
          <div className={`relative champion-profile-container page_${page}`}>
            {getChampionProfileSEO(page, {
              ...(championData && { champion: { name: championData.name, key: championId } }),
              ...(params.multi && { multi: params.multi }),
              patch: validatedParamsWithDefaults.validatedParams.patch,
              language: language,
              role,
            })}
            <ChampionProfileBackground />
            <ChampionProfileHeader className="mb-[24px]" />
            <ChampionProfilePageNav />
            <ChampionProfileFilterManager className="my-[24px]" />
            <div className="champion-profile-page">
              <ChampionProfileRoutes />
            </div>
          </div>
        </div>
      </div>
    </RailAdsContainer>
  );
}

function RailAdsContainer(props: { children: React.ReactNode | React.ReactNode[] }) {
  return (
    <div className={classNames("flex mx-auto w-full max-md:w-full")}>
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
        <div className="flex-1 mr-[-12px]">
          <RampLeftRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
      {props.children}
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
        <div className="flex-1 ml-[-12px]">
          <RampRightRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
    </div>
  );
}
