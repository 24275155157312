import { useContext, createContext } from "react";
import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { IndividualChampion } from "@outplayed/riot-assets";
import { RoleS } from "@ugg/shared/utils/role-helpers";

export interface ChampionProfileContextInterface {
  isLoading: boolean;
  isError: boolean;
  championData: IndividualChampion | null;
  championId: number;
  role: string | undefined;
  multi: string | undefined;
  // page: keyof typeof validParams;
  page: CHAMPION_PAGES | SPECIAL_CHAMPION_PAGES;
}

export const initialState = {
  isLoading: true,
  isError: false,
  championData: null,
  championId: 0,
  role: undefined,
  multi: undefined,
  page: CHAMPION_PAGES.OVERVIEW,
};

export const ChampionProfileContext = createContext<ChampionProfileContextInterface>(initialState);

export function useChampionProfileContext() {
  return useContext(ChampionProfileContext);
}
