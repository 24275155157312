import classNames from "classnames";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { useValidatedChampionProfileParams } from "@ugg/shared/api/requests/champions/common";
import { useChampionRankingStats } from "@ugg/shared/api/requests/champions/ranking-stats";
import { getRankings } from "@ugg/shared/api/data-parser/champions/ranking-stats";
import { MediaQuery } from "@outplayed/responsive";
import SEOFluff from "./SEOFluff";
import { ChampionIconContainer } from "./ChampionIconContainer";
import { HeaderPatch } from "@ugg/shared/components/common/HeaderPatch";
import ChampionSkillWithLabel from "@ugg/shared/components/common/Skills/ChampionSkillWithLabel";
import { TooltipOrientations } from "@outplayed/tooltips";
import { getLuTier, getLuTierColorHex } from "@ugg/shared/utils/tier-helpers";
import { capitalizeMulti } from "@ugg/shared/utils/multi-build-helpers";
import { useChampionPageTitle } from "./useChampionPageTitle";

export function ChampionProfileHeader({ className }: { className?: string }) {
  const { championData, championId, page, role, multi } = useChampionProfileContext();
  const { name } = championData || {};
  const pageTitle = useChampionPageTitle();

  const { validatedParams } = useValidatedChampionProfileParams(championId, page, {
    keepDefaultParams: true,
    ssr: true,
  });

  const validatedParamsRankingStats = {
    queueType: validatedParams.queueType,
    region: validatedParams.region,
    rank: validatedParams.rank,
    role,
  };

  const {
    data: rankingStats,
    loading: fetchingRankingStats,
    error: errorRankingStats,
  } = useChampionRankingStats(championId, {
    ssr: true,
    skip: !role,
    forcedParams: validatedParamsRankingStats,
  });

  if (!championData || !role) {
    return null;
  }

  const filteredRankingStats = getRankings(rankingStats, { ...validatedParamsRankingStats, role });
  const { stdevs, rank } = filteredRankingStats || {};
  const tier = (stdevs && rank && getLuTier(stdevs)) || undefined;
  const tierColor = !stdevs || !rank ? "#414165" : tier === "B" ? "#CDDCFE" : getLuTierColorHex(stdevs);

  const skillSlots = [
    { skillKey: "Q", skillSlot: 0 },
    { skillKey: "W", skillSlot: 1 },
    { skillKey: "E", skillSlot: 2 },
    { skillKey: "R", skillSlot: 3 },
  ];

  return (
    <div className={classNames(className)}>
      <div className="flex items-start w-full max-sm:items-start max-sm:relative max-sm:mb-[66px]">
        <ChampionIconContainer championId={championId} tier={tier} tierColor={tierColor} />
        <div className="flex-1 flex flex-col justify-between min-w-0 h-full ml-[24px] max-sm:ml-[12px] max-sm:self-end">
          <h1 className="flex items-center whitespace-nowrap max-sm:flex-col max-sm:items-start font-['Barlow']">
            <span className="mr-[12px] text-[36px] font-semibold max-sm:text-[28px] max-sm:w-full max-sm:truncate">
              {`${multi ? capitalizeMulti(multi, "") + " " : ""}${name}`}
            </span>
            <span className="text-[36px] font-normal text-lavender-50 max-sm:truncate max-sm:w-full max-sm:text-[28px] max-xs:text-[24px]">
              {pageTitle}
            </span>
            {validatedParams.patch && (
              <MediaQuery min="TABLET" max="DESKTOP_LARGE">
                <HeaderPatch className="ml-[10px]" patch={validatedParams.patch.replace("_", ".")} />
              </MediaQuery>
            )}
          </h1>
          <div
            className="
            flex items-center h-[32px] mt-[18px]
            max-sm:absolute max-sm:top-full max-sm:!mt-[12px] max-sm:left-0 
          "
          >
            <div className="flex-none flex items-center mr-[24px] gap-[6px]">
              <ChampionSkillWithLabel
                className="[&_img]:w-[36px] [&_img]:h-[36px] [&_img]:rounded-[3px] max-sm:[&_img]:w-[30px] max-sm:[&_img]:h-[30px]"
                label={"P"}
                labelPosition="bottomCenter"
                isPassive
                championId={championId}
                forceOrientation={TooltipOrientations.BOTTOM}
                ssr
              />
              {skillSlots.map((skill) => (
                <ChampionSkillWithLabel
                  key={skill.skillKey}
                  className="[&_img]:w-[36px] [&_img]:h-[36px] [&_img]:rounded-[3px] max-sm:[&_img]:w-[30px] max-sm:[&_img]:h-[30px]"
                  label={skill.skillKey}
                  labelPosition={"bottomCenter"}
                  championId={championId}
                  skillSlot={skill.skillSlot}
                  forceOrientation={TooltipOrientations.BOTTOM}
                  ssr
                />
              ))}
            </div>
            <SEOFluff className="pr-[12px] text-lavender-50 text-[12px] max-sm:text-[11px] font-medium max-xs:hidden" />
          </div>
        </div>
      </div>
      <SEOFluff className="mt-[24px] pr-[12px] text-lavender-50 text-[11px] font-medium xs:hidden" />
    </div>
  );
}
