import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { ReactComponent as SearchIcon } from "@ugg/shared/assets/svg/search-icon.svg";
import { ReactComponent as XButton } from "@ugg/shared/assets/svg/x.svg";
import { Champion, getRiotAssetsContext } from "@outplayed/riot-assets";

type ChampionOption = {
  value: number;
  name: string;
  normalizedName: string;
};

export interface VSChampionFilterProps {
  value?: number;
  onChange: (value: ChampionOption | null) => void;
}

export default function VSChampionFilter(props: VSChampionFilterProps) {
  const { value, onChange } = props;
  const { getChampionName, useChampionMini, getNormalizedChampionName, getChampionIdByName, getChampionImgFromSprite } =
    getRiotAssetsContext();

  const { data: championMini } = useChampionMini({ ssr: true });
  const [searchValue, setSearchValue] = useState("");
  const [currentSearchOption, setCurrentSearchOption] = useState<ChampionOption | null>(
    value && value > 0
      ? {
          value,
          name: getChampionName(value),
          normalizedName: getNormalizedChampionName(value),
        }
      : null,
  );
  const [inputActive, setInputActive] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(0);

  const multiFilterRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputActive) {
      inputRef.current?.focus();
    } else if (!currentSearchOption) {
      setSearchValue("");
      setCurrentSearchOption(null);
    }
  }, [inputActive, currentSearchOption]);

  const onApply = (championOption: ChampionOption | null) => {
    setCurrentSearchOption(championOption);
    onChange(championOption);
    setInputActive(false);
  };

  const onClear = () => {
    onApply(null);
  };

  const filterOptions = (options: Champion[]) => {
    return options.filter(({ name }) => {
      const normalizedChampionName = name.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
      const normalizedInput = searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
      return normalizedChampionName.startsWith(normalizedInput);
    });
  };

  const filteredChampionMini = filterOptions((championMini && Object.values(championMini)) || []);
  const championOptions: ChampionOption[] = filteredChampionMini
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ key, name }) => ({
      value: Number(key),
      name,
      normalizedName: getNormalizedChampionName(key),
    }));

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onApply(championOptions[highlightedItem]);
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedItem(Math.abs(championOptions.length + highlightedItem - 1) % championOptions.length);
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedItem(Math.abs(championOptions.length + highlightedItem + 1) % championOptions.length);
    }
  };

  useEffect(() => {
    const outsideClick = (e: MouseEvent) => {
      if (
        multiFilterRef.current &&
        e.target !== null &&
        !multiFilterRef.current.contains(e.target as HTMLElement) &&
        document?.contains(e.target as HTMLElement)
      ) {
        setInputActive(false);
      }
    };
    window?.addEventListener("click", outsideClick);

    return () => window?.removeEventListener("click", outsideClick);
  }, [inputActive]);

  const championSelected = currentSearchOption ? "champ-selected" : "no-champ";
  const searching = inputActive ? "searching" : "";

  return (
    <div className="multi-filter-champion" ref={multiFilterRef}>
      <div
        className={`multi-filter-label ${championSelected} ${searching}`}
        onClick={() => setInputActive(true)}
        style={{ width: "170px", height: "36px" }}
      >
        {!inputActive && currentSearchOption ? (
          <div className="multi-filter-label-tags">
            <div className="multi-filter-tag" onClick={() => setInputActive(true)}>
              <span>vs.</span>
              <div className="champion-icon">{getChampionImgFromSprite(currentSearchOption.value, { size: 16 })}</div>
              <div className="champion-name">{getChampionName(currentSearchOption.value)}</div>
              <XButton
                className="x-icon w-[12px] h-[12px]"
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <input
              ref={inputRef}
              placeholder={"vs. Champion..."}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setHighlightedItem(0);
              }}
              onKeyDown={onKeyDown}
            />
            <SearchIcon className="search-icon" />
          </>
        )}
      </div>
      {inputActive && (
        <div className="multi-filter-menu-summoner-page">
          {championOptions.map((option, index) => (
            <div
              key={option.value}
              className={classnames("multi-filter-menu-item", index === highlightedItem && "highlighted")}
              onMouseEnter={() => setHighlightedItem(index)}
              onClick={() => onApply(option)}
            >
              <div className="champion-icon" style={{ overflow: "hidden", marginRight: "8px" }}>
                {getChampionImgFromSprite(option.value, { size: 20 })}
              </div>
              <div>{getChampionName(option.value)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
