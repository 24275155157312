import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { QueueTypeBalances } from "@ugg/shared/interfaces/champions/queue-type-balances.interface";

export function useAramData(version?: string, options: JSONFetcherOptions = {}) {
  const { getWorkingPatch } = getRiotAssetsContext();
  const patch = getWorkingPatch(version).split(".").slice(0, -1).join("_");

  const url = `https://static.bigbrain.gg/assets/lol/queue_type_champion_changes/aram/${patch}.json`;
  return useJSONFetcher<QueueTypeBalances>(url, options);
}

export function useUrfData(version?: string, options: JSONFetcherOptions = {}) {
  const { getWorkingPatch } = getRiotAssetsContext();
  const patch = getWorkingPatch(version).split(".").slice(0, -1).join("_");

  const url = `https://static.bigbrain.gg/assets/lol/queue_type_champion_changes/urf/${patch}.json`;
  return useJSONFetcher<QueueTypeBalances>(url, options);
}
