import { round } from "@ugg/shared/utils/math";
import { calculateKDA } from "@ugg/shared/utils/kda";
import { RoleC, RoleN } from "@ugg/shared/utils/role-helpers";
import { RegionC, RegionN } from "@ugg/shared/utils/region-helpers";
import { RankC, RankN } from "@ugg/shared/utils/rank-helpers";
import { ChampionStats, RankingStats, Counter } from "@ugg/shared/interfaces/champions/ranking-stats.interface";

import { getKey, getData } from "./common";

export interface RankingStatsInfo {
  roleWins: ChampionStats[0];
  roleMatches: ChampionStats[1];
  rank: ChampionStats[2];
  total_rank: ChampionStats[3];
  avg_damage: number;
  avg_gold: number;
  avg_kda: string;
  avg_cs: number;
  win_rate: number;
  pick_rate: number;
  ban_rate: number;
  bans: ChampionStats[10];
  total_matches: ChampionStats[11];
  counters: Array<{
    champion_id: Counter[0];
    wins: Counter[1];
    matches: Counter[2];
    win_rate: number;
  }>;
  real_matches: ChampionStats[13];
  stdevs: ChampionStats[14];
  effective_winrate: ChampionStats[15];
  distribution_count: ChampionStats[16];
  distribution_mean: ChampionStats[17];
  distribution_stdevs: ChampionStats[18];
  be_all_picks: ChampionStats[19];
}

export function getRankings(
  data: ReturnType<typeof cleanRankingsData>,
  params: {
    queueType: string;
    region: string;
    rank: string;
    role: string;
  },
) {
  return getData(data, params);
}

export function cleanRankingsData(data: RankingStats) {
  const cleanData: Record<string, RankingStatsInfo> = {};
  const regionKeys: RegionN[] = Object.keys(data || {}).map(Number);
  for (const regionId of regionKeys) {
    const regionData = data[regionId];
    const rankKeys: RankN[] = Object.keys(regionData || {}).map(Number);

    for (const rankId of rankKeys) {
      const rankData = regionData[rankId];
      const roleKeys: RoleN[] = Object.keys(rankData || {}).map(Number);

      for (const roleId of roleKeys) {
        const roleData = rankData[roleId];

        let [
          roleWins,
          roleMatches,
          rank,
          total_rank,
          damage,
          gold,
          kills,
          assists,
          deaths,
          cs,
          bans,
          total_matches,
          counters,
          real_matches,
          stdevs,
          effective_winrate,
          distribution_count,
          distribution_mean,
          distribution_stdevs,
          be_all_picks,
        ] = roleData;

        const parsedCounters = counters.map((counter) => {
          const matches = counter[2];

          return {
            champion_id: counter[0],
            wins: counter[1],
            matches,
            win_rate: round((counter[1] / matches) * 100, 2),
          };
        });

        const rankingsData: RankingStatsInfo = {
          roleWins,
          roleMatches,
          rank,
          total_rank,
          avg_damage: damage / roleMatches,
          avg_gold: gold / roleMatches,
          avg_kda: calculateKDA(kills, deaths, assists),
          avg_cs: round(cs / roleMatches, 0),
          win_rate: round((roleWins / roleMatches) * 100, 2),
          pick_rate: round((roleMatches / total_matches) * 100, 1),
          ban_rate:
            real_matches !== 0 && real_matches !== null && real_matches !== undefined
              ? round((bans / real_matches) * 100, 1)
              : round((bans / total_matches) * 100, 1),
          counters: parsedCounters,
          stdevs,
          effective_winrate,
          bans,
          real_matches,
          total_matches,
          distribution_count,
          distribution_mean,
          distribution_stdevs,
          be_all_picks,
        };

        const key = getKey(RegionC.convertToString(regionId), RankC.convertToString(rankId), RoleC.convertToString(roleId));

        cleanData[key] = rankingsData;
      }
    }
  }
  return cleanData;
}
