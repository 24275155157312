import classNames from "classnames";
import { ReactComponent as Tools } from "@ugg/shared/assets/svg/tools.svg";

interface MoreLessButtonProps {
  className?: string;
  collapsed: boolean;
  onClick: () => void;
}

export function MoreLessButton(props: MoreLessButtonProps) {
  const { className, collapsed, onClick } = props;

  const label = collapsed ? "More..." : "Less...";

  return (
    <div
      className={classNames(
        "flex items-center h-[36px] px-[12px] py-[8px] bg-purple-400 rounded-[3px] hover:bg-[#313160] hover:cursor-pointer",
        className,
      )}
      onClick={onClick}
    >
      <Tools className="flex-none mr-[10px] w-[16px] h-[16px]" />
      <span className="font-['Inter'] text-[14px] font-bold text-white">{label}</span>
    </div>
  );
}
