import { useTranslation } from "react-i18next";
import { MediaQuery } from "@outplayed/responsive";
import { useActiveSpecialModes, SPECIAL_GAME_MODES } from "@ugg/shared/utils/special-game-modes";
import { useGlobal } from "reactn";
import { useParams } from "react-router-dom";
import { getQueueTypeName } from "@ugg/shared/utils/queue-type-helpers";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { PageNav, NavTab } from "@ugg/shared/components/common/PageNav";
import { championProfileUrlPages } from "@ugg/shared/pages/champion-pages";
import {
  getChampionBuildUrl,
  getChampionCountersUrl,
  getChampionMatchupsUrl,
  getChampionItemsUrl,
  getChampionItemPathsUrl,
  getChampionRunesTableUrl,
  getChampionRuneSetsUrl,
  getChampionSpellsUrl,
  getChampionDuosUrl,
  getChampionLeaderboard,
  AppRoutes,
  getSpecialGameModeChampionBuildUrl,
} from "@ugg/shared/routes/app-routes";

export function ChampionProfilePageNav({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { isLoading, championData, championId, page, role, multi } = useChampionProfileContext();
  const [language] = useGlobal("language");
  const { champion } = useParams<{ champion: string }>();

  const activeModes = useActiveSpecialModes({ isChampBuildPage: true, ssr: true });
  const specialModeTabs: NavTab[] = [];
  activeModes.forEach((mode) => {
    const { urlName, buildPageName, newTag } = SPECIAL_GAME_MODES[mode];
    return specialModeTabs.push({
      to: getSpecialGameModeChampionBuildUrl(champion, urlName),
      label: getQueueTypeName(mode),
      reset: true,
      new: newTag,
      activePaths: [championProfileUrlPages[buildPageName]],
    });
  });

  const tabs: NavTab[] = [
    {
      to: getChampionBuildUrl(champion, { language }),
      label: t("Build"),
      activePaths: [AppRoutes.CHAMPION_PROFILE_BUILD],
    },
    ...specialModeTabs,
    { to: getChampionCountersUrl(champion), label: t("Counters") },
    {
      to: getChampionLeaderboard(champion),
      label: t("Leaderboards"),
    },
    {
      customComponent: (
        <a
          className="pbs-tab flex items-center"
          target="_blank"
          href={`https://probuildstats.com/champion/${champion}`}
          rel="noreferrer"
        >
          <img
            className="pbs-logo inline-flex relative top-[1px] w-[13px] mr-[10px]"
            src="https://static.bigbrain.gg/assets/probuildstats/logos/pbs-logo.svg"
            alt="ProbuildStats"
          />
          {t("Pro Builds")}
        </a>
      ),
    },
    {
      label: t("More Stats"),
      dropdown: [
        { to: getChampionMatchupsUrl(champion), label: t("Matchups") },
        { to: getChampionItemsUrl(champion), label: t("Items") },
        { to: getChampionItemPathsUrl(champion), label: t("Build Paths") },
        { to: getChampionRunesTableUrl(champion), label: t("Runes Table") },
        { to: getChampionRuneSetsUrl(champion), label: t("Rune Sets") },
        { to: getChampionSpellsUrl(champion), label: t("Spells & Abilities") },
        { to: getChampionDuosUrl(champion), label: t("Duos") },
      ],
    },
  ];

  const mobileTabs = [
    {
      to: getChampionBuildUrl(champion, { language }),
      label: t("Build"),
      activePaths: [AppRoutes.CHAMPION_PROFILE_BUILD],
    },
    // ...specialModeTabs,
    { to: getChampionCountersUrl(champion), label: t("Counters") },
    {
      to: getChampionLeaderboard(champion),
      label: t("Leaderboards"),
    },
    {
      customComponent: (
        <a
          className="pbs-tab flex items-center"
          target="_blank"
          href={`https://probuildstats.com/champion/${champion}`}
          rel="noreferrer"
        >
          <img
            className="pbs-logo inline-flex relative top-[1px] w-[13px] mr-[10px]"
            src="https://static.bigbrain.gg/assets/probuildstats/logos/pbs-logo.svg"
            alt="ProbuildStats"
          />
          {t("Pro Builds")}
        </a>
      ),
    },
  ];

  return (
    <div className={className}>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        <PageNav tabs={mobileTabs} />
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE">
        <PageNav tabs={tabs} />
      </MediaQuery>
    </div>
  );
}
