import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { RoleS } from "@ugg/shared/utils/role-helpers";
import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

export function useChampionPageTitle() {
  const { championId, page, role } = useChampionProfileContext();
  const routeParams = useParams<{ role: string }>();
  const currentQueryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams(validParams, true);
  const validatedParams = validateQueryParams(CHAMPION_PAGES.OVERVIEW, currentQueryParams, true);

  const validPageParams: Record<string, any> = (page && validParams[page]) || {};
  const rankOptions = validPageParams.rank || [];
  const currentRank = rankOptions.find((rank: FilterOption) => rank.value === validatedParams.rank);
  const rank = currentRank && currentRank.label;

  const { t } = useTranslation();
  const roleTextMap: { [key in string]?: string } = {
    [RoleS.BOTTOM]: "ADC",
    [RoleS.SUPPORT]: "Support",
    [RoleS.MIDDLE]: "Mid",
    [RoleS.TOP]: "Top",
    [RoleS.JUNGLE]: "Jungle",
  };
  const roleText = role && t(roleTextMap[role] || "");

  switch (page) {
    case CHAMPION_PAGES.OVERVIEW: {
      if (routeParams?.role) {
        return `${roleText} Build, ${t(rank)}`;
      }
      return `${t(`Build for`)} ${roleText}, ${t(rank)}`;
    }
    case CHAMPION_PAGES.ITEMS:
      return `${t(`Items for`)} ${roleText}`;
    case CHAMPION_PAGES.ITEM_PATHS:
      return `${t(`Build Paths for`)} ${roleText}`;
    case CHAMPION_PAGES.SPELLS_ABILITIES:
      return `${t(`Spells and Abilities for`)} ${roleText}`;
    case CHAMPION_PAGES.RUNES_TABLE:
      return `${roleText} ${t(`Runes Table`)} `;
    case CHAMPION_PAGES.RUNE_SETS:
      return `${roleText} ${t(`Runes Sets`)} `;
    case CHAMPION_PAGES.COUNTERS:
      return `${t(`Counter for`)} ${roleText}`;
    case CHAMPION_PAGES.MATCHUPS:
      return `${t(`Matchups for`)} ${roleText}`;
    case CHAMPION_PAGES.DUOS:
      return `${t(`Duos for`)} ${roleText}`;
    case SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM:
      return `${t("ARAM Build & Runes")}`;
    case SPECIAL_CHAMPION_PAGES.OVERVIEW_ARURF:
      return `${t("ARURF Build & Runes")}`;
    case SPECIAL_CHAMPION_PAGES.OVERVIEW_URF:
      return `${t("URF Build & Runes")}`;
    case SPECIAL_CHAMPION_PAGES.OVERVIEW_ONE_FOR_ALL:
      return `${t("One for All Build & Runes for")} ${roleText}`;
    case SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ:
      return `${t("Nexus Blitz Build & Runes")}`;
    case SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA:
      return `${t("Arena Build")}`;
    default:
      return "";
  }
}
