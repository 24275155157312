import classNames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";

export function ChampionProfileBackground({ className }: { className?: string }) {
  const { getChampionSplashImg } = getRiotAssetsContext();
  const { championId } = useChampionProfileContext();
  const championSplashImg = getChampionSplashImg(championId);

  return (
    <div className={classNames("absolute top-0 bottom-0 left-0 right-0 z-[-1] h-full", className)}>
      <div className="relative">
        <div className="flex items-start justify-center w-full overflow-hidden">
          <img className="w-full" src={championSplashImg} />
        </div>
        <div className="absolute top-0 w-full h-full">
          <div className="w-full h-full bg-[50%_0px] bg-[linear-gradient(180deg,rgba(7,7,32,0.28)_0%,#070720_50%),linear-gradient(270deg,#070720_2.27%,rgba(7,7,32,0.00)_37.69%),radial-gradient(60%_40%_at_68%_15.89%,rgba(7,7,32,0.00)_0%,#070720_100%)]" />
        </div>
      </div>
    </div>
  );
}
