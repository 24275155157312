import classnames from "classnames";
import { compile } from "path-to-regexp";
import { useParams, useRouteMatch, Link } from "react-router-dom";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { useTranslation } from "react-i18next";
import { TooltipContainer } from "@outplayed/tooltips";
import { buildQueryParams } from "@ugg/shared/utils/url-query-params";
import { normalizeRole, RoleS } from "@ugg/shared/utils/role-helpers";
import { ROLE_OPTIONS, ALL_ROLE_OPTIONS } from "@ugg/shared/query-params/filter-options/common";
import { FilterSelect } from "@ugg/shared/interfaces/filter-manager.interface";

interface RoleSplitTypeProps extends FilterSelect {
  className?: string;
  role?: string;
  allRoles?: boolean;
  disableTooltip?: boolean;
}

const ChampionOverviewRoleSplit = (props: RoleSplitTypeProps) => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const { path } = useRouteMatch();
  const queryParams = useQueryString();
  const { className, role, allRoles, filters, disableTooltip } = props;

  const displayRoleFilters = () => {
    const selectedRole = normalizeRole(role || filters.role);
    if (queryParams.role) {
      delete queryParams.role;
    }

    const roleOptions = allRoles ? ALL_ROLE_OPTIONS : ROLE_OPTIONS;
    const toPath = compile(path, { validate: false });

    return roleOptions.map((option) => {
      const isSelectedRole = selectedRole === normalizeRole(option.value);
      const selectorClassNames = classnames("role-filter", {
        active: isSelectedRole,
      });

      return (
        <TooltipContainer key={option.value} tooltipInfo={t(option.label)} disableTooltip={disableTooltip}>
          <Link
            className={selectorClassNames}
            to={{
              // Reset multi
              pathname: toPath({ ...urlParams, multi: undefined, role: option.value }),
              search: buildQueryParams(queryParams),
            }}
          >
            {isSelectedRole && option.imgActive ? option.imgActive : option.img}
          </Link>
        </TooltipContainer>
      );
    });
  };

  return <div className={classnames("role-filter-container", className)}>{displayRoleFilters()}</div>;
};

export default ChampionOverviewRoleSplit;
