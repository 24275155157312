import { window } from "global";
import classNames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { useLanguage } from "@ugg/shared/hooks/use-language-hooks";
import { Helmet } from "react-helmet-async";

import { getRoleLabel } from "@ugg/shared/utils/role-helpers";
import { SPECIAL_CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { capitalizeMulti } from "@ugg/shared/utils/multi-build-helpers";
import { useUGGAssetsContext } from "@ugg/shared/components/UGGAssetsProvider";

interface BuildSEOProps {
  className?: string;
  displayPatch: string;
  rank: string;
}

export default function BuildSEO(props: BuildSEOProps) {
  const { championId, page, role } = useChampionProfileContext();
  const { className, displayPatch, rank } = props;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const routeParams = useParams<{ role: string; multi: string }>();
  const [lang] = useLanguage();

  const { staging } = useUGGAssetsContext();
  const envDir = staging ? "staging" : "prod";
  const {
    data: buildChampionNames,
    loading: fetchingBuildChampionNames,
    error: errorBuildChampionNames,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/seo-champion-names.json`, { ssr: true });

  let modifiedRoleText = getRoleLabel(role || "");
  if (modifiedRoleText === "Bottom") {
    modifiedRoleText = "ADC";
  }
  modifiedRoleText = t(modifiedRoleText);

  if (!window) {
    if (location.pathname.includes("/tr_tr")) {
      i18n.changeLanguage("tr_TR");
    }
  }

  if (!buildChampionNames) {
    return null;
  }

  let blurbType = routeParams?.multi ? "Mature multi" : "Mature";
  const champion = buildChampionNames[championId];
  if ("blurbType" in champion) {
    blurbType = champion["blurbType"];
  }

  let specialGameMode = "";
  if (page && (Object.values(SPECIAL_CHAMPION_PAGES) as string[]).includes(page)) {
    specialGameMode = {
      [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM]: "ARAM",
      [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARURF]: "ARURF",
      [SPECIAL_CHAMPION_PAGES.OVERVIEW_PICK_URF]: "URF",
      [SPECIAL_CHAMPION_PAGES.OVERVIEW_ONE_FOR_ALL]: "One For All",
      [SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ]: "Nexus Blitz",
      [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA]: "Arena",
    }[page as SPECIAL_CHAMPION_PAGES];

    blurbType = routeParams?.multi ? "multi special" : specialGameMode;
  }

  let champ1 = champion["name"];
  let champ2 = champion["name"];
  let champ3 = champion["name"];
  // if ("altName" in champion) {
  //   champ2 = champion["altName"];
  // }
  // if ("altName2" in champion) {
  //   champ3 = champion["altName2"];
  // }

  let blurb = t(`Build ${blurbType.toLowerCase()} blurb`, {
    champ1: champ1,
    champ2: champ2,
    champ3: champ3,
    role: modifiedRoleText,
    rank: rank,
    patch: displayPatch,
    multi: routeParams?.multi ? capitalizeMulti(routeParams?.multi, "") : "",
    game: specialGameMode,
  });

  return (
    <div className={classNames(className)}>
      <Helmet>
        <meta http-equiv="content-language" content={lang} />
      </Helmet>
      {blurb}
    </div>
  );
}
